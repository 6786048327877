import React, {useCallback, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import {StaticImage} from "gatsby-plugin-image"
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {PostcodeCTA, PostcodeCTAInput} from '../../components/PostcodeCTA/PostcodeCTA';
import GreenCheckMark from "../../components/icons/GreenCheckMark";
import "../../../scss/main.scss";
import {MicroCombo} from "../../components/Trustpilot/TrustpilotWidgets";
import LocationMark from "../../components/icons/Locations/LocationMark";
import Arrow from "../../components/icons/LiveInCare/Arrow";
import {SINGLE_TIER_ONE} from "../../constants/pricing";
import CqcApproved from "../../components/CqcApproved";
import Included from "../../components/icons/LiveInCare/Included";
import NotIncluded from "../../components/icons/LiveInCare/NotIncluded";
import {CalendlyEventListener, InlineWidget} from "react-calendly";
import {graphql, useStaticQuery} from "gatsby";

const isBrowser = typeof window !== "undefined"

export default function Home() {
    const [matches, setMatches] = useState()

    const {
        site: {
            siteMetadata: {
                appConfig: {
                    gtmContainerId
                }
            }
        }
    } = useStaticQuery(
        graphql`
		  query {
            site {
                siteMetadata {
                    appConfig {
                        gtmContainerId
                    }
                }
            }
          }
        `)

    useEffect(() => {
        if (!gtmContainerId) return
        window.dataLayer = window.dataLayer || [];

        function gtag(){window.dataLayer.push(arguments)};
        gtag('js', new Date());
        gtag('config',gtmContainerId);
    }, [gtmContainerId])

    useEffect(() => {
        if (!isBrowser) return;

        const mediaMatch = window.matchMedia('(min-width: 800px)');
        setMatches(mediaMatch.matches)
        const handler = e => setMatches(e.matches);
        mediaMatch.addEventListener('change', handler);
        return () => mediaMatch.removeEventListener('change', handler);
    }, []);

    const trackCalendlyEvent = useCallback((interaction_type) => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: 'calendly_event',
            interaction_type
        });
    }, [])

    return (
        <>
            <Helmet>
                <meta charset="UTF-8"/>
                <meta http-equiv="X-UA-Compatible" content="IE=edge"/>

                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>Edyn Care | Looking for care</title>
                <meta property="og:title" content="Edyn Care | Looking for care"/>
                <meta property="og:description" content="High quality care in the comfort of your own home."/>
                <meta property="og:image"
                      content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
                <meta property="og:url" content="https://www.edyn.care/live-in-care/looking-for-care"/>
            </Helmet>
            {/*   ========== Nav ==========  */}
            <div className='container main-content'>
                <Header/>
                <main className='looking-for-care'>
                    <section>
                        <div className="looking-for-care-landing">
                            <div className="looking-for-care-landing-left">
                                <div className="landing-content-container">
                                    <div className="looking-for-care-heading">
                                        <h1>
                                            Live-in care helping your loved ones thrive at home
                                        </h1>
                                        <p className="primary">
                                            Personalised compassionate care from trusted live-in professional carers.
                                            Tailored to your family's needs.
                                        </p>
                                    </div>
                                    <div className="landing-left-bottom">
                                        <MicroCombo classNames={"trustpilot-minimal-no-bg"}/>
                                        <PostcodeCTA/>
                                    </div>
                                </div>
                            </div>
                            <div className="looking-for-care-landing-right">
                                <StaticImage className="location-img"
                                             src={'../../../static/assets/marketing-site/images/carer-helping-with-haircut.png'}/>
                                <div className="location-landing-name">
                                    <LocationMark className="icon-default"/>
                                    <p>Sarah supporting Ahir with hygiene in London</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='care-benefits-section'>
                        <div className="care-benefits wide-view">
                            <h1> What can my professional carer help with </h1>
                            <div className="benefit benefit__grid-column-left">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-04.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Medication management</h3>
                                    <br/>
                                    <p className="primary">
                                        Our carers are trained to promptly and efficiently
                                        administer medication to ensure your wellbeing.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-right">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-07.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Meal preparation</h3>
                                    <br/>
                                    <p className="primary">
                                        Carers will plan your meals according to your dietary
                                        requirements, nutritional needs and personal preferences,
                                        including cooking your favourite meals and shopping for your
                                        groceries.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-left">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-05.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Personal hygiene</h3>
                                    <br/>
                                    <p className="primary">
                                        Our carers can provide assistance with dressing, grooming,
                                        bathing and toileting.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-right">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-02.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Support with mobility</h3>
                                    <br/>
                                    <p className="primary">
                                        Our carers are trained to ensure they help their clients
                                        move about safely whether this with mobilities aids or
                                        hoists.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-left">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-06.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Housekeeping</h3>
                                    <br/>
                                    <p className="primary">
                                        Our carers can help out with chores like laundry, light
                                        cleaning, linen and taking out rubbish maintaining a safe environment.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-right">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-01.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Emotional support</h3>
                                    <br/>
                                    <p className="primary">
                                        Your care plan will outline all your social interests and
                                        preferences and the carer will help you maintain your
                                        activities and hobbies, both in and out of your home.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit benefit__grid-column-left">
                                <div className="benefit-img">
                                    <StaticImage placeholder="blurred"
                                                 src="../../../static//assets/marketing-site/images/livein-icon-03.png"
                                                 height={120}/>
                                </div>
                                <div className="benefit-descrip">
                                    <h3>Companionship</h3>
                                    <br/>
                                    <p className="primary">
                                        Carefully matching the right carers for our clients helps
                                        build long lasting harmonious relationships, so they get to
                                        know your personality traits and when you need space.
                                    </p>
                                </div>
                            </div>
                            <div className="benefit custom benefit__grid-column-right">
                                <div className="benefit-descrip">
                                    <p className="secondary">
                                        We offer specialist support for conditions including:
                                    </p>
                                    <div className="benefit-list">
                                        <ul>
                                            <li>
                                                <Link to='/condition/dementia/'>
                                                    <Arrow className="gatsby-image-wrapper"/>
                                                    <p>Dementia</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/condition/parkinsons/'>
                                                    <Arrow className="gatsby-image-wrapper"/>
                                                    <p>Parkinsons</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/condition/multiple-sclerosis/'>
                                                    <Arrow className="gatsby-image-wrapper"/>
                                                    <p>MS care</p>
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <Link to='/condition/cancer/'>
                                                    <Arrow className="gatsby-image-wrapper"/>
                                                    <p>Cancer care</p>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/condition/stroke/'>
                                                    <Arrow className="gatsby-image-wrapper"/>
                                                    <p>Stroke care</p>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* <ul>

										</ul> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='pricing-section-contact-us-banner'>
                        <div className="contact-us-banner">
                            <StaticImage placeholder="blurred"
                                         className="pricing-postcode-people"
                                         src="../../../static/assets/marketing-site/images/care-team-people.png"
                                         alt="Pricing People"
                                         objectFit="contain"
                            />
                            <div className="contact-us-text">
                                <p>
                                    Enter your postcode or speak with a friendly care advisor:
                                </p>
                                <h3>
                                    <a href="tel:020 3970 9900">020 3970 9900</a>
                                </h3>
                            </div>
                            <div className="post-input-custom">
                                <PostcodeCTAInput/>
                            </div>
                        </div>
                    </section>
                    <section className='livein-reasons-section'>
                        <div className="livein-reasons">
                            <h1 className="mobile-view">The benefits of live-in care</h1>

                            <div className="livein-images">
                                <StaticImage placeholder="blurred"
                                             src="../../../static/assets/marketing-site/images/livein-imageclus-02.jpg"
                                             className='livein-image'
                                />
                                <StaticImage placeholder="blurred"
                                             src="../../../static//assets/marketing-site/images/livein-imageclus-03.jpg"/>
                                <StaticImage placeholder="blurred"
                                             src="../../../static//assets/marketing-site/images/livein-imageclus-06.png"/>
                                <StaticImage placeholder="blurred"
                                             className="stretch"
                                             src="../../../static//assets/marketing-site/images/livein-imageclus-10.png"
                                />
                                <StaticImage placeholder="blurred"
                                             className="stretch"
                                             src="../../../static//assets/marketing-site/images/livein-imageclus-09.png"
                                />
                            </div>
                            <div className="livein-context">
                                <h1>
                                    The benefits of{" "}
                                    <br/>
                                    live-in care
                                </h1>
                                <br/>
                                <ul>
                                    <li>
                                        <GreenCheckMark className='livein-reasons__green-check-mark'/>
                                        <div className="livein-list">
                                            <h3>Professional care at home</h3>
                                            <br/>
                                            <p className="primary">
                                                Enduring the stress, upheaval and uncertainty of moving
                                                into a care home is tough. There is no place like home
                                                with it’s familiarity and possessions around you.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <GreenCheckMark className='livein-reasons__green-check-mark'/>
                                        <div className="livein-list">
                                            <h3>Tailored to you</h3>
                                            <br/>
                                            <p className="primary">
                                                Our carefully matched carers get to know your loved
                                                one’s like they were a family member.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <GreenCheckMark className='livein-reasons__green-check-mark'/>
                                        <div className="livein-list">
                                            <h3>Maintaining your lifestyle</h3>
                                            <br/>
                                            <p className="primary">
                                                One-to-one care enables your loved one to live the life
                                                they wish - no rigid timetables or set routines.
                                                Continue to live to their rhythm with the additional
                                                help they require.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <GreenCheckMark className='livein-reasons__green-check-mark'/>

                                        <div className="livein-list">
                                            <h3>With professional carers</h3>
                                            <br/>
                                            <p className="primary">
                                                It’s no surprise the calibre of carers and our training
                                                sets us apart: only 5% of applicants meet our standards.
                                                We then train them in-house to ensure they understand
                                                our values.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <GreenCheckMark className='livein-reasons__green-check-mark'/>

                                        <div className="livein-list">
                                            <div className="livein-list">
                                                <h3>More affordable</h3>
                                                <br/>
                                                <p className="primary">
                                                    Surprisingly to many live-in care is often more cost
                                                    effective than people initially realise. A popular
                                                    misconception is that 24 hour care is prohibitively
                                                    expensive when compared to the cost of a care home.
                                                    <br/>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <CqcApproved/>
                    <section className='reliable-carers'>
                        <div className="container our-carers">
                            <section className="oc-reliable-section">
                                <div className="oc-reliable__description-container">
                                    <h3 className="oc-reliable__description-title">Reliable carers in your area. Trained
                                        by
                                        us.</h3>
                                    <div className="oc-reliable__description">
                                        <p>Our professional carers provide both care and companionship to clients. We
                                            have a
                                            stringent recruitment and training process with only 5% of applicants
                                            joining
                                            the
                                            team. Our ethos is simple: better treated carers leads to better care.</p>
                                        <p>They are the missing piece in your care journey, allowing you to relax in the
                                            comfort
                                            that everything is in place.</p>
                                        <p>Our recruitment and training team do all the necessary safety checks and
                                            training-
                                            giving you peace of mind.</p>
                                    </div>
                                </div>
                                <div className="oc-reliable__points-card">
                                    <CheckMarkElement title="First-class training programme"/>
                                    <CheckMarkElement title="DBS checked"/>
                                    <CheckMarkElement title="Rigorous safe checks"/>
                                    <CheckMarkElement title="24/7 access to our care management team"/>
                                    <CheckMarkElement title="Excellent spoken and written English"/>
                                    <CheckMarkElement title="Public Liability Insurance included"/>
                                    <CheckMarkElement title="Ability prepare and cook nutritious meals"/>
                                    <CheckMarkElement title="COVID-19 trained"/>
                                    <CheckMarkElement title="Policies + procedures overseen by edyn"/>
                                    <StaticImage src="../../../static/assets/marketing-site/images/carer-1.png"
                                                 placeholder="blurred" objectFit="contain" height={150}/>
                                </div>
                            </section>
                        </div>
                    </section>
                    <MicroCombo classNames={'trustpilot-rating-banner'}/>
                    <section className='pricing-section-contact-us-banner'>
                        <div className="contact-us-banner">
                            <StaticImage placeholder="blurred"
                                         className="pricing-postcode-people"
                                         src="../../../static//assets/marketing-site/images/care-team-people.png"
                                         alt="Pricing People"
                                         objectFit="contain"
                            />
                            <div className="contact-us-text">
                                <p>
                                    Enter your postcode or speak with a friendly care advisor:
                                </p>
                                <h3>
                                    <a href="tel:020 3970 9900">020 3970 9900</a>
                                </h3>
                            </div>
                            <div className="post-input-custom">
                                <PostcodeCTAInput/>
                            </div>
                        </div>
                    </section>
                    <section className='book-a-call'>
                        <div className='book-a-call-heading'>
                            <h2>Book a call with an edyn.care family care advisor</h2>
                        </div>
                        <div className='book-a-call-content'>
                        <ul>
                                <li>
                                    <GreenCheckMark className='livein-reasons__green-check-mark'/>
                                    <div className="livein-list">
                                        <h3>Understand your care needs</h3>
                                        <br/>
                                        <p className="primary">
                                            Our care advisors will help you to understand the level of care you need
                                            and whether live in care is suitable for you and your family.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <GreenCheckMark className='livein-reasons__green-check-mark'/>

                                    <div className="livein-list">
                                        <h3>Understand your payment options</h3>
                                        <br/>
                                        <p className="primary">
                                            We understand that care is expensive. Our care advisors will help you to find
                                            the right funding options for you e.g. NHS continuing healthcare.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <GreenCheckMark className='livein-reasons__green-check-mark'/>

                                    <div className="livein-list">
                                        <h3>Understand the setup process</h3>
                                        <br/>
                                        <p className="primary">
                                            Our care advisors will help you understand the process to setup live-in care
                                            and what you need to have in place to get care started.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div style={{width: '100%', height: 'auto'}}>
                                <CalendlyEventListener
                                    onEventTypeViewed={() => {
                                        trackCalendlyEvent('event_type_viewed')
                                    }} onEventScheduled={() => {
                                    trackCalendlyEvent('event_scheduled')
                                }} onDateAndTimeSelected={() => {
                                    trackCalendlyEvent('date_and_time_selected')
                                }} onProfilePageViewed={() => {
                                    trackCalendlyEvent('profile_page_viewed')
                                }}
                                >
                                </CalendlyEventListener>
                                <InlineWidget
                                    url='https://calendly.com/d/cqdy-tpd-9kv/looking-for-care-consultation?&hide_gdpr_banner=1'
                                    styles={{
                                        height: matches ? '800px' : '1120px'
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <section className='live-in-care-comparison'>
                        <div className="livein-compare">
                            <h2>How we compare</h2>

                            <p className="secondary heading no-border">Care home</p>
                            <p className="secondary heading no-border">
                                Introductory agencies
                            </p>
                            <div className="compare-item green no-border heading">
                                <StaticImage placeholder="blurred"
                                             style={{width: '50%'}}
                                             src="../../../static//assets/marketing-site/images/logo.png"
                                             objectFit='contain'
                                />
                            </div>

                            <p className="secondary sub-heading">We train our carers in house</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static/assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">1:1 carer support</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">Dedicated care team</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">Comfort of your own home</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">Connect to your community</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">
                                Oversight of care, nutrition and medication
                            </p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">Understanding of well-being</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">In field quality assurance team</p>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>

                            <p className="secondary sub-heading">Family can visit and stay anytime</p>
                            <div className="compare-item no-border mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Care home</p>
                                </div>
                                <NotIncluded className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item no-border mobile">
                                <div className="compact-view">
                                    <p className="secondary no-border">Trained Carers</p>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                            <div className="compare-item green no-border mobile last">
                                <div className="compact-view">
                                    <div className="compare-item green no-border">
                                        <StaticImage placeholder="blurred"
                                                     style={{width: '50%'}}
                                                     src="../../../static//assets/marketing-site/images/logo.png"
                                                     objectFit='contain'
                                        />
                                    </div>
                                </div>
                                <Included className="gatsby-image-wrapper"/>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="customer-say">
                            <div className="customer-words">
                                <p className="secondary">What our customers say:</p>
                                <div className="customer-words-group">
                                    <h3 className="text-with-quotes">
                                        <span className="customer-words-group__quotes">“</span>
                                        What makes Edyn stand out from other agencies we have used
                                        for live-in care is the fact that they care - the human
                                        touch is there.<span className="customer-words-group__quotes">”</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="customer-images">
                                <StaticImage
                                    src="../../../static//assets/marketing-site/images/dementia-quote-customers.png"
                                    objectFit='contain'
                                    placeholder="blurred"
                                />
                                <div className="customer-names">
                                    <p className="secondary">
                                        Sarah M.
                                        <br/>
                                        <span>Mum</span>
                                    </p>
                                    <p className="secondary">
                                        Julie M.
                                        <br/>
                                        <span>Daughter</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="approach-care">
                            <div className="left-description">
                                <h3 className="title">Exceptional care is not an individual job</h3>
                                <div className="description">It takes a team working together to deliver our clients
                                    truly exceptional care. A Care Team consists of a group of specialists committed to
                                    the key areas of our client’s support.
                                </div>
                            </div>
                            <div className="right-description">
                                <div className="title">Our care teams consist of:</div>
                                <div className="images-flex">
                                    <div className="image-description">
                                        <StaticImage
                                            src='../../../static//assets/marketing-site/images/caretaker-img-01.png'
                                            objectFit="contain"/>
                                        <h3 className="">A main and respite carer</h3>
                                        <p>They provide day-to-day support and help in your home.</p>
                                    </div>
                                    <div className="image-description">
                                        <StaticImage
                                            src='../../../static//assets/marketing-site/images/family-care-coordinator-01.png'
                                            objectFit="contain"/>
                                        <h3>Family care coordinator</h3>
                                        <p>They will ensure you have the best carers matched to you providing the
                                            consistent help day in day out.</p>
                                    </div>
                                    <div className="image-description">
                                        <StaticImage
                                            src='../../../static//assets/marketing-site/images/caretaker-img-02.png'
                                            objectFit="contain"/>
                                        <h3>Family Care Manager</h3>
                                        <p>They are here to ensure the care and support we provide is aligned to your
                                            desired outcomes and changing needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="how-edyn-works">
                        <h1 className="live-in-heading">
                            How edyn works
                        </h1>
                        <div className="reasons">
                            <div className="reason">
                                <StaticImage placeholder="blurred"
                                             className="img-live-in"
                                             src="../../../static/assets/marketing-site/images/support-teams.png"
                                             alt="proffesional carers"
                                />
                                <h3>Speak with our care advisors</h3>
                                <p className="primary">
                                    Each one of our carers is trained by an in house care support team.

                                </p>
                            </div>
                            <div className="reason">
                                <StaticImage placeholder="blurred"
                                             className="img-live-in"
                                             src="../../../static/assets/marketing-site/images/livein-icon-03.png"
                                             alt="Support Teams"
                                />
                                <h3>We come visit you at home to find out more</h3>
                                <p className="primary">
                                    Our care managers will come draw up a tailored care plan to your care needs
                                </p>
                            </div>
                            <div className="reason">
                                <StaticImage placeholder="blurred"
                                             className="img-live-in"
                                             src="../../../static/assets/marketing-site/images/proffesional-carer.png"
                                             alt="Technology"
                                />
                                <h3>You interview carers and choose your favorite</h3>
                                <p className="primary">
                                    We will propose vetted and trained potential carers that meet your needs
                                </p>
                            </div>
                            <div className="reason">
                                <StaticImage placeholder="blurred"
                                             className="img-live-in"
                                             src="../../../static/assets/marketing-site/images/carer-and-woman.png"
                                             alt="Support Teams"
                                />
                                <h3>Care begins and you can relax</h3>
                                <p className="primary">
                                    Our quality assurance team will manage your care journey with us
                                </p>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer/>
            </div>
        </>
    );
}


const CheckMarkElement = ({title = "title"}) =>
    <div className="check-mark-element">
        <GreenCheckMark/>
        {title}
    </div>